import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';

const Content = ({ buildTime }) => (
  <>
    <p>
      © 1999 - {new Date().getFullYear()} &middot; 
      {` `}
      <a href="https://www.blockworksSoftware.com">Blockworks Software</a>
    </p>
  </>
);

const links = {
  firstCol: [
  ],
  secondCol: [
  ],
};

const Logo = () => (
  <svg 
    viewBox="0 0 512 512" 
    xmlns="http://www.w3.org/2000/svg">
      <path d="" fill="#878d96"/>
  </svg>  
);

const CustomFooter = () => <Footer links={links} Content={Content} Logo={Logo} />;

export default CustomFooter;
