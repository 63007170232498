// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-devops-best-practices-mdx": () => import("./../../../src/pages/blog/devops-best-practices.mdx" /* webpackChunkName: "component---src-pages-blog-devops-best-practices-mdx" */),
  "component---src-pages-blog-legacy-app-inventory-mdx": () => import("./../../../src/pages/blog/legacy-app-inventory.mdx" /* webpackChunkName: "component---src-pages-blog-legacy-app-inventory-mdx" */),
  "component---src-pages-blog-portfolio-management-mdx": () => import("./../../../src/pages/blog/portfolio-management.mdx" /* webpackChunkName: "component---src-pages-blog-portfolio-management-mdx" */),
  "component---src-pages-blog-target-app-inventory-mdx": () => import("./../../../src/pages/blog/target-app-inventory.mdx" /* webpackChunkName: "component---src-pages-blog-target-app-inventory-mdx" */),
  "component---src-pages-blog-test-diagram-mdx": () => import("./../../../src/pages/blog/test-diagram.mdx" /* webpackChunkName: "component---src-pages-blog-test-diagram-mdx" */),
  "component---src-pages-components-migrate-ado-to-ado-mdx": () => import("./../../../src/pages/components/migrate-ado-to-ado.mdx" /* webpackChunkName: "component---src-pages-components-migrate-ado-to-ado-mdx" */),
  "component---src-pages-components-trip-wrapper-mdx": () => import("./../../../src/pages/components/trip-wrapper.mdx" /* webpackChunkName: "component---src-pages-components-trip-wrapper-mdx" */),
  "component---src-pages-components-work-item-property-widget-mdx": () => import("./../../../src/pages/components/work-item-property-widget.mdx" /* webpackChunkName: "component---src-pages-components-work-item-property-widget-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-portal-index-js": () => import("./../../../src/pages/portal/index.js" /* webpackChunkName: "component---src-pages-portal-index-js" */),
  "component---src-pages-portal-philosophy-js": () => import("./../../../src/pages/portal/philosophy.js" /* webpackChunkName: "component---src-pages-portal-philosophy-js" */),
  "component---src-pages-test-test-mdx": () => import("./../../../src/pages/test/test.mdx" /* webpackChunkName: "component---src-pages-test-test-mdx" */),
  "component---src-pages-work-item-property-widget-mdx": () => import("./../../../src/pages/work-item-property-widget.mdx" /* webpackChunkName: "component---src-pages-work-item-property-widget-mdx" */)
}

