import React from "react"
import { useNavItems as themeUseNavItems } from 'gatsby-theme-carbon/src/util/NavItems';
import { useAuth0 } from "@auth0/auth0-react"

// add nav items
export function useNavItems() {
  const { isAuthenticated, loading, logout, user, loginWithPopup } = useAuth0()
  if (loading) {
    return <p>Loading...</p>
  }

  const navItems = themeUseNavItems();
  const navPortalItems = navItems.concat({
    title: 'Portal',
    pages: [
      { path: '/portal', title: 'Portal Home' },
      { path: '/portal/philosophy', title: 'Philosophy' },
    ],
  });

  if (isAuthenticated) {
    // if (user && user.permissions && user.permissions.includes(permission)) {
    //   return navPortalItems;
    // }
  }

  return navItems.concat({
    title: 'Portal',
    pages: [
      { path: '/portal', title: 'Portal Home' },
    ],
  });
}